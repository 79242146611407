import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet es reconeix fàcilment per la seva forma i per l’olor pudent com de carn podrida que atreu les mosques. Inicialment es desenvolupa sota terra en forma d’ou rodó i blanc, amb uns rizoides o cordons miceloides blancs i gruixuts. Posteriorment va sortint i creix en forma de randa de color vermell intens i les parets internes verdes que inclouen les espores. Aquestes són de color blanc terrós en massa, el·líptiques, de 5 x 6 micres. Pot arribar a mesurar 10 cm d’altura.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      